<template>
  <div class="content">
    <PageHeader
      :breadcrumbLinks="breadcrumbLinks"
      title="Mais"
      :subtitle="$t('generic-str.event-log')"
    />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <LogList/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/rebranding/PageHeader.vue';
import LogList from '@/components/rebranding/logs-report/LogList.vue';

export default {
  name: 'AdminLogs',
  components: {
    PageHeader,
    LogList,
  },
  data() {
    return {
      breadcrumbLinks: {
        1: '/admin/logs',
        2: '',
      },
    };
  },
};
</script>
<style lang="scss" scoped>
</style>
